import './css/Navigation.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EgamesyLogo from '../../Assets/Brand/EgamesyLogo.png'
import { ReactComponent as Hamburger } from '../../Assets/menu.svg'
//import Badge from '@mui/material/Badge';

//import UserAvatar from '../../Assets/user.svg';
//import { Hidden } from '@mui/material';
import axios from 'axios';
//import {useSignIn} from 'react-auth-kit';

interface IConnectResponse{
    isSuccess: boolean;
    link: string;
}

const Nav: React.FC<{}> = () =>{
    const navigate = useNavigate();
    //const signIn = useSignIn();

    const clickToPaslaugos = () => {
        navigate('/paslaugos');
    };
    const clickToForumas = () => {
        window.location.href = `https://forumas.lsrg.lt/`;
    };

    const prisijungtiEvent = async () => {
        try {
            //let redirectString = "http://localhost:5117/auth/discord"
            let redirectString = "/auth/discord"
            //console.log("Nenaudojama");
            /*if (process.env.REACT_APP_NODE_ENV === 'production') {
                redirectString = "/auth/discord"
            }*/
            const response = await axios.post<IConnectResponse>(redirectString, {}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.isSuccess) {
                console.log(response.data)
                window.open(response.data.link, "_self")
            }
            else
            {
                console.log(response.data)
            }
        } catch (error) {
          console.error(error);
        }
    };

     // to change burger classes
     const [menu_class, setMenuClass] = useState("menu hidden")
     const [isMenuClicked, setIsMenuClicked] = useState(false)

     // toggle burger menu change
    const updateMenu = () => {
        if(!isMenuClicked) {
            setMenuClass("menu visible")
        }
        else {
            setMenuClass("menu hidden")
        }
        setIsMenuClicked(!isMenuClicked)
    }

    return(
        <div className="navContaineris">
            <div>
                <img className="logo h-16" src={EgamesyLogo} alt="logo" height="50px" />
            </div>
            <div>
            <div className={menu_class}>
                <ul className="burgerlinks">
                    <li className="burgerlink"><a href="/">Pagrindinis</a></li>
                    <li className="burgerlink" onClick={clickToPaslaugos}>Paslaugos</li>
                    {/* <Badge className="burgerlink" badgeContent="Naujiena" color="error"> */}
                        <li onClick={clickToForumas}>Forumas</li>
                    {/* </Badge> */}
                    <li className="burgerlink"onClick={prisijungtiEvent}>VVP</li>
                </ul>
            </div>
                <div className="burger-menu" onClick={updateMenu}></div>
                <ul className="navlinks">
                    <li><a className="link" href="/">Pagrindinis</a></li>
                    <li className="link" onClick={clickToPaslaugos}>Paslaugos</li>
                    {/* <Badge badgeContent="Naujiena" color="error"> */}
                        <li className="link" onClick={clickToForumas}>Forumas</li>
                    {/* </Badge> */}
                </ul>
            </div>
            <div className="hamburger" onClick={updateMenu}>
                <Hamburger />
            </div>
            {/* <div className="accountButton" onClick={prisijungtiEvent}>
                <img src={UserAvatar} alt="gtav"/>
            </div> */}
        </div>
    );
}

export default Nav;