import './css/Paslaugos.css';
import Nav from '../Components/navigation/NavigationNew';

// import EurSvg from '../Assets/eur.svg'
// import dDiamond from '../Assets/d-diamond.svg'
// import Procentas from '../Assets/procentas.svg'
// import EgIcon from '../Assets/Brand/egIcon.png'

// import gtaLeft from '../Assets/gta-left.png'
// import gtaRight from '../Assets/gta-right.png'

import React, { useState } from 'react';
import axios from 'axios';

interface IPaymentModel {
    name: string;
    money: string;
    kredits: string;
    klaida: string;
}

interface IPaymentResponse{
    success: boolean;
    message: string;
    data?: any;
    link: string;
}

function About() {
    const [paymentData, setValue] = useState<IPaymentModel>({ name: "", money: "2", kredits: "100", klaida: ""});
    //const [paymentResponse] = useState<IPaymentResponse>({ Response: ""});

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({ ...paymentData, name: event.target.value });
    };
    const handleMoneyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let mValue = event.target.value;

        let mKredits = Math.floor(parseInt(mValue)*100/2)/*/2*/
        if(Number.isNaN(mKredits)) mKredits = 0;
        if(parseInt(mValue) > 10) mKredits = Math.floor(mKredits * 1.15);
        
        setValue({ ...paymentData, money: mValue, kredits: mKredits.toString(), klaida: "" });
    };

    const handlePurchase = async () => {
        try {
            // /api/Paysera
            // http://localhost:5117/api/Paysera
          const response = await axios.post<IPaymentResponse>("/api/Paysera", {
            ...paymentData
          }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
          });
          if (response.data.success) {
            console.log(`Success ${response.data.message}`)
            openPayLink(response.data.link)
            // Handle successful purchase
          } else {
            console.log(`Fail ${response.data.message}`)
            // Handle failed purchase
          }
        } catch (error) {
          // Handle error
          console.error(error);
        }
    };

    const openPayLink = (link: string) => {
        window.open(link)
    };

    const submitBuy = () => {
        //console.log(paymentData.name + " " + paymentData.money + " " + paymentData.kredits)
        if(paymentData.name.length < 3 || paymentData.name.length > 24){
            setValue({ ...paymentData, klaida: "Prašome nurodyti teisingą slapyvardį V_P formatu!" });
            return;
        }

        let words = paymentData.name.split('_');
        if(words.length < 2 || words[0].length < 3 || words[1].length < 3){
            setValue({ ...paymentData, klaida: "Prašome nurodyti teisingą slapyvardį V_P formatu!" });
            return;
        }

        let mValue = parseInt(paymentData.money);
        //console.log(mValue)
        if(mValue < 2 || mValue > 1000){
            setValue({ ...paymentData, klaida: "Suma 2-1000!" });
            return;
        }

        // Pradedame pirkimą
        handlePurchase();
    };
    return (

        <div>
     <div className='bg-bgp bg-cover bg-no-repeat h-[1100px]'>
        <div className='bg-grids h-full'>
          <div className='linear w-full h-full'>
           <Nav />
           <div className='max-w-2xl px-3 m-auto mt-[210px] text-black bg-black bg-opacity-25 rounded-[8px] pb-5'>
            <div className='flex justify-center items-center'>
                                <div className="flex flex-col">
                                    <div className='m-auto'>

                                    {/* <Image src='/icon.svg' alt="icon" width={100} height={100} className='m-auto mt-5' /> */}
                                        <h1 className='text-2xl text-white mt-5 text-center'>Kreditų pirkimas</h1>
                                    <div className="mt-5">
                                        <div className='flex flex-col gap-y-2'>

                                        <label className='text-white'>Žaidimo slapyvardis</label>
                                            <input
                                                type={'text'}
                                                id="name"
                                                name="name"
                                                maxLength={24}
                                                placeholder="Vardas_Pravardė"
                                                value={paymentData.name}
                                                onChange={handleNameChange}
                                                className='
                                                w-[300px]
                                                h-[50px]
                                                px-2
                                                bg-black
                                                bg-opacity-50
                                                border
                                                border-white
                                                border-opacity-50
                                                text-white
                                                rounded-md
                                                '
                                                // v-on:keypress="updateSlapyvardis"
                                                // @paste="onPaste"
                                                />
                                                </div>
                    
                                    </div>

                                    <div className="mt-5">
                                        <label className='text-white'>Papildymo suma eurais</label>
                                            <div className="mt-2">
                                                <input
                                                    // :style="(this.fields.money < 2 || this.fields.money > 1000) ? 'outline: 2px solid red' : 'outline: none'"
                                                    type="number"
                                                    required
                                                    //id="money"
                                                    //name="money"
                                                    min="2"
                                                    max="9999"
                                                    maxLength={4}
                                                    placeholder="Suma"
                                                    value={paymentData.money}
                                                    onChange={handleMoneyChange}
                                                    className='
                                                    w-[300px]
                                                    h-[50px]
                                                    px-2
                                                    bg-black
                                                    bg-opacity-50
                                                    border
                                                    border-white
                                                    border-opacity-50
                                                    text-white
                                                    rounded-md
                                                    '
                                                    // @input="updateSuma"
                                                    // @paste="onPaste"
                                                    // @keypress="onlyNumber"
                                                    />
                                            </div>
                                                <h1 className='text-white mt-5'>Gaunama žaidimo kreditų suma</h1>
                                                <input
                                                    type="text"
                                                    id="diamond"
                                                    name="diamond"
                                                    placeholder="100"
                                                    min="100"
                                                    max="100000"
                                                    disabled={true}
                                                    value={paymentData.kredits}
                                                    className='
                                                    w-[300px]
                                                    h-[50px]
                                                    px-2
                                                    bg-black
                                                    bg-opacity-50
                                                    border
                                                    border-white
                                                    border-opacity-50
                                                    text-white
                                                    rounded-md
                                                    mt-2
                                                    '
                                                    // @input="updateKreditai"
                                                    // @paste="onPaste"
                                                    />
                                    </div>
                                </div>
                                    <div className='flex flex-col items-center gap-y-4 mt-5'>
                                <button onClick={submitBuy} className='w-[300px] h-[50px] bg-white rounded-md uppercase font-medium '>Pirkti</button>
                                    {paymentData.klaida !== "" && <h1 className='text-red-600 text-center'>{paymentData.klaida}</h1>}
                                    </div>
                                    <div className="text-white bg-gray-500 bg-opacity-25 text-center rounded-md w-[300px] m-auto mt-5 h-[100px] items-center flex justify-center">
                                        <div className='flex flex-col'>

                                        <p>Gaukite <span className='text-yellow-300 font-medium'> 15% </span>daugiau,</p>
                                        <p>pirkdami kreditų  nuo <span className='text-yellow-300'>10 eurų </span></p>
                                        <p>Nuolaida pritaikoma <span className='font-medium'>automatiškai!</span></p>
                                        </div>
                                    </div>
                                <div className="text-white text-center mt-5">
                                    <p>Paslaugų sąrašą rasite apačioje </p>
                                    <p>Pagalba dėl apmokėjimo problemų: <br /> info@egamesy.lt arba Discord: tuk1s</p>
                                </div>
                                        </div>
                                        </div>
                            </div>
          </div>
      </div>
    </div>
    <div className='bg-fot'>
    {/* <Vip /> */}

    </div>

  </div>



       
    )
}

export default About