import './css/Landing.css';
import Nav from '../Components/navigation/Navigation';

import BgVideo from '../Assets/video/egpromo.mp4';
import BgLogo from '../Assets/main.png';

import AltLogo from '../Assets/Brand/altLogo.png';
import DiscordLogo from '../Assets/Brand/discordLogo.png';
import GtaLogo from '../Assets/Brand/gtaLogo.png';
import EgIcon from '../Assets/Brand/egIcon.png';

import Sistemos0 from '../Assets/sistemos0.png'
import Sistemos1 from '../Assets/sistemos1.png'
import Sistemos2 from '../Assets/sistemos2.png'

import gtaLeft from '../Assets/gta-left.png'
import gtaRight from '../Assets/gta-right.png'

import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface IUserCounts {
    registerUsers: number;
    onlineUsers: number;
    discordUsers: number;
}

function Home() {
    const [userCounts, setUserCounts] = useState<IUserCounts>({
        registerUsers: 0,
        onlineUsers: 0,
        discordUsers: 0
    });

    useEffect(() => {
        // Fetch user counts from the backend API using Axios
        // http://localhost:5117/api/Landing
        // /api/Landing
        //
        axios.get<IUserCounts>('/api/Landing')
          .then(response => {
            //console.log(response);
            setUserCounts(response.data);
          })
          .catch(error => {
            console.error(error);
          });
      }, []);

    const clickToGta = () => {
        window.open('https://store.steampowered.com/app/271590/Grand_Theft_Auto_V/', '_blank')
    };
    const clickToAltV = () => {
        window.open('https://cdn.alt-mp.com/launcher/release/x64_win32/altv.exe', '_blank')
    };
    const clickToDiscord = () => {
        window.open('https://discord.com/download', '_blank')
    };
    const connectToServer = () => {
        window.open('https://altv.run/lsrg', '_blank')
    };
    const connectToDiscord = () => {
        window.open('https://discord.gg/MsW76n4', '_blank')
    };
    return (
      <div>
        
        
        <div className="headeris">
            <div className="containeris">
                
                <Nav/>
                
                    <div className="header-info-block">
                        <div className="header-info">

                            <video className='bgvideo' 
                            src={BgVideo} muted loop autoPlay poster={BgLogo}> 
                            </video>

                            <div className="leftpanel">
                                <h1>Sveiki atvykę į lsrg.lt</h1>
                                <p>
                                    Sistemos išsiskiriančios iš kitų, visi atnaujinimai daromi mūsų pačių, todėl žaidėjų norai ir idėjos yra mūsų prioritetas. Adrenalino tikrai netruks, gaujų ir teisėsaugos sistemos leis pajusti veiksmo skonį kurio taip trūksta RP stiliaus žaidimuose.
                                </p>
                                <p>
                                    Išskrtinė ALT:V platforma leis pajusti geriausią įmanoma sinchronizaciją. Visos sistemos kuriamos mūsų pačių, todėl viskas yra optimizuota - daugiau jokių striginėjimų! Laukiame tavęs!
                                </p>
                                {/* <h6>Prisijunk jau DABAR ir gauk 3 dienas nemokamas privilegijas!</h6> */}
                                <div className="version">
                                    <div className="version-title">
                                        {/* <h1 onClick={connectToServer}><i className="fa-solid fa-right-to-bracket"></i> Prisijungti į serverį</h1> */}
                                        <h1 onClick={connectToServer}><i className="fa-solid fa-right-to-bracket"></i> Prisijungimas į serverį</h1>
                                        <h1 onClick={connectToDiscord}><i className="fa-solid fa-headset"></i> Prisijungti į discord</h1>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="pristatymas">
                                <iframe
                                    width="453"
                                    height="280"
                                    src={`https://www.youtube.com/embed/uXex5Y-5CP0`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            </div> */}

                        </div>
                    </div>
                    <div className="server-info-block">
                        <div className="server-info">
                            <h1>{userCounts.onlineUsers}<span>Prisijungusių žaidėjų</span></h1>
                        </div>
                        <div className="server-info middle-child">
                            <h1>{userCounts.discordUsers}+ <span>Discord narių</span></h1>
                        </div>
                        <div className="server-info">
                            <h1>{userCounts.registerUsers}+ <span>Užsiregistravusių žaidėjų</span></h1>
                        </div>
                    </div>
                
            </div>
        </div>

    <section id="server-join-page">
        <div className="containeris">
            <div className="join-page-block">
                <div className="join-page">
                    <div className="title">
                        <h1>Kaip prisijungti prie mūsų?</h1>
                        <p>Jums prireiks apačioje pateiktų programėlių</p>
                    </div>
                    <div className="download-info-block">
                        <div className="download-block">
                            <div className="download">
                                <img src={GtaLogo} alt="gtav" />
                                <h1>Grand Theft Auto V</h1>
                                <p>Nusipirkite originalią žaidimo kopiją, kad galėtumėt žaisti.</p>
                                <button onClick={clickToGta}>Įsigyti</button>
                            </div>
                        </div>
                        <div className="download-block">
                            <div className="downlaod">
                                <img src={AltLogo} alt="gtav" />
                                <h1>Alt:V Multiplayer</h1>
                                <p>
                                    Norėdami prisijungti prie serverio, jums reikia alt:V platformos
                                    paleidėjo.
                                </p>
                                <button onClick={clickToAltV}>Atsisiųsti</button>
                            </div>
                        </div>
                        <div className="download-block">
                            <div className="download">
                                <img src={DiscordLogo} alt="gtav" />
                                <h1>Discord</h1>
                                <p>
                                    Norėdami žaisti serveryje, turite būtinai autorizuotis discord
                                    kanale.
                                </p>
                                <button onClick={clickToDiscord}>Atsisiųsti</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="activities">
        <div className="activities-grid text-center">
            <div className="activities-grid-block back-img">
                <div className="left-column-img">
                    <img src={Sistemos0} alt="sistemos" />
                </div>
            </div>
            <div className="activities-grid-block grid-title right-column-text">
                <div className="grid-text">
                    <h1>Serverio sistemos</h1>
                    <p>
                        Visos projekto sistemos yra kuriamos nuo pamatų, todėl tai užtrunka ilgiau,
                        tačiau duoda unikalumo ir įdomumo serveriui. Atsižvelgdami į žaidėjų ir
                        testuotojų idėjas bei pastabas naujausias sistemas koreguojame ir taisome
                        pagal daugumos nuomonę.
                    </p>
                </div>
            </div>
            <div className="activities-grid-block grid-title left-column-text">
                <div className="grid-text">
                    <h1>Nelegalios veiklos</h1>
                    <p>
                        Norite pasirinkti geriečio pusę - prisijunk prie teisėsaugos! Nori atlikti
                        nešvarius darbelius ir uždirbti nešvarių pinigėlių bei rizika pasiekti šlovę
                        - junkis prie mafijos, pasiek tai vienas arba su savo geriausių draugų
                        grupele! O gal atlikti didžiulį grobimą pakvies Tave slaptas asmuo.. kas gi
                        žino? Viską teks atrasti pačiam!
                    </p>
                </div>
            </div>
            <div className="activities-grid-block back-img right-column-img">
                <div className="right-column-img">
                    <img src={Sistemos1} alt="veiklos" />
                </div>
            </div>
            <div className="activities-grid-block back-img">
                <div className="left-column-img">
                    <img src={Sistemos2} alt="ivairove" />
                </div>
            </div>
            <div className="activities-grid-block grid-title right-column-text">
                <div className="grid-text">
                    <h1>Darbų įvairovė</h1>
                    <p>
                        Šiame serveryje galėsite taip pat rasti daugumos mėgstamas ir ieškomas
                        legalias veiklas, įkurti savo verslą ar nusipirkti parduodamą! Mūsų komanda
                        yra pasiryžusi išvystyti jūsų pateiktas rimtas sistemas. Už aiškiai ir
                        plačiai aprašytas idėjas bei praneštas esamas klaidas įvertiname, bei
                        apdovanojame.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <footer>
        <div className="footer-block">
            <div className="footer-grid-block left-column footer-img">
                <img src={gtaLeft} alt="" />
            </div>
            <div className="footer-grid-block middle-column">
                <div className="middle-info">
                    <img src={EgIcon} alt="icon" />
                    <div className="icons">
                        <a href="https://www.facebook.com/LSRGserveris" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook"></i></a>
                        <a href="https://www.tiktok.com/@lsrglt" target="_blank" rel="noreferrer"><i className="fa-brands fa-tiktok"></i></a>
                        <a href="https://discord.gg/MsW76n4" target="_blank" rel="noreferrer"><i className="fa-brands fa-discord"></i></a>
                        <a href="https://www.youtube.com/channel/UC0lSoA7MHRRZwUWgh9qggkA" target="_blank" rel="noreferrer"><i className="fa-brands fa-youtube"></i></a>
                    </div>
                    <p>© 2024 lsrg.lt</p>
                </div>
            </div>
            <div className="footer-grid-block right-column footer-img">
                <img src={gtaRight} alt="" />
            </div>
        </div>
    </footer>
      </div>
    );
  }
  
  export default Home;