import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom"
import Landing from "./Pages/Landing"
import Paslaugos from "./Pages/Paslaugos"
import PaslaugosNew from "./Pages/PaslaugosNew"
import User from "./Pages/User"

const App: React.FC = () => {
  // eslint-disable-next-line
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    document.title = 'Lsrg';
  }, []);

  return (
    <div className="App">
      
        <Routes>
          <Route path='*' element={<Landing />} />
          <Route path="/" element={<Landing />} />
          <Route path="paslaugos" element={ <Paslaugos /> } />
          <Route path="paslaugosnew" element={ <PaslaugosNew /> } />
          <Route path="user" Component={() => <User setIsAuth={setIsAuth}/>} />
        </Routes>

    </div>
  );
}
export default App;
