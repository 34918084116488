import React from "react";

interface Props{
    setIsAuth: React.Dispatch<React.SetStateAction<boolean>>
}

const User: React.FC<Props> = () => {
    return (
        <div>User page</div>
    );
}

export default User;