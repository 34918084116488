import './css/Navigation.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../../Assets/Brand/logo.png'
// import { ReactComponent as Hamburger } from '../../Assets/menu.svg'
//import Badge from '@mui/material/Badge';

//import UserAvatar from '../../Assets/user.svg';
//import { Hidden } from '@mui/material';
// import axios from 'axios';
//import {useSignIn} from 'react-auth-kit';

// interface IConnectResponse{
//     isSuccess: boolean;
//     link: string;
// }

const Nav: React.FC<{}> = () =>{
    const [isOpen, setIsOpen ] = useState(false)
    const toggleNavBar = () => {
        setIsOpen(!isOpen)
    }

    const navigate = useNavigate();
    //const signIn = useSignIn();

    const clickToPaslaugos = () => {
        navigate('/paslaugos');
    };
    const clickToForumas = () => {
        window.location.href = `https://forumas.lsrg.lt/`;
    };

    // const prisijungtiEvent = async () => {
    //     try {
    //         //let redirectString = "http://localhost:5117/auth/discord"
    //         let redirectString = "/auth/discord"
    //         //console.log("Nenaudojama");
    //         /*if (process.env.REACT_APP_NODE_ENV === 'production') {
    //             redirectString = "/auth/discord"
    //         }*/
    //         const response = await axios.post<IConnectResponse>(redirectString, {}, {
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             }
    //         });

    //         if (response.data.isSuccess) {
    //             console.log(response.data)
    //             window.open(response.data.link, "_self")
    //         }
    //         else
    //         {
    //             console.log(response.data)
    //         }
    //     } catch (error) {
    //       console.error(error);
    //     }
    // };

     // to change burger classes
    //  const [menu_class, setMenuClass] = useState("menu hidden")
    //  const [isMenuClicked, setIsMenuClicked] = useState(false)

     // toggle burger menu change
    // const updateMenu = () => {
    //     if(!isMenuClicked) {
    //         setMenuClass("menu visible")
    //     }
    //     else {
    //         setMenuClass("menu hidden")
    //     }
    //     setIsMenuClicked(!isMenuClicked)
    // }

    return(
        <nav className="items-center w-full justify-between mr-auto px-3 pt-5">
    <div className="flex justify-between items-center max-w-7xl m-auto px-3">
        <div className="flex justify-start items-center text-center mr-auto ">
    {/* <Image src="/logo.svg" width={120} height={50} alt="open" className="mx-auto"/> */}
            <img width={120} height={50} src={Logo} alt="logo" />
        </div>
    <div className="lg:flex  items-center hidden  mx-auto gap-5 text-white uppercase">
                <ul className="navlinks">
                    <li><a className="link" href="/">Pagrindinis</a></li>
                    <li className="link" onClick={clickToPaslaugos}>Paslaugos</li>
                    {/* <Badge badgeContent="Naujiena" color="error"> */}
                        <li className="link" onClick={clickToForumas}>Forumas</li>
                    {/* </Badge> */}
                </ul>
            {/* <Link href='/' className="text-yellow-300 font-medium " > Pradžia</Link> */}
            <a href="/" className="text-yellow-300 font-medium "> Pradžia</a>
            {/* <Link href='/paslaugos' > Paslaugos</Link> */}
            <a href="/paslaugos" className="text-yellow-300 font-medium "> Paslaugos</a>
            {/* <Link href='' > Forumas</Link> */}
            <a href="/" onClick={clickToForumas} className="text-yellow-300 font-medium "> Forumas</a>
            {/* <Link href='' > Wiki</Link> */}

    </div>
    <div className="ml-auto hidden lg:flex gap-1">
      <a href="/">
    {/* <Image src='/fb.png' alt="fb" width={40} height={40} className="bg-slate-50 bg-opacity-20 p-3 rounded-full hover:bg-slate-50 hover:bg-opacity-50 cursor-pointer" /> */}
      </a>
      <a href="/">

      {/* <Image src='/discord.svg' alt="dc" width={40} height={30} className="bg-slate-50 bg-opacity-20 p-3 rounded-full hover:bg-slate-50 hover:bg-opacity-50 cursor-pointer" /> */}
      </a>
      <a href="/">
      {/* <Image src='/tik.svg' alt="tik" width={40} height={30} className="bg-slate-50 bg-opacity-20 p-3 rounded-full hover:bg-slate-50 hover:bg-opacity-50 cursor-pointer" /> */}
      </a>
      <a href="/">
      {/* <Image src='/instagram.svg' alt="ig" width={40} height={30} className="bg-slate-50 bg-opacity-20 p-3 rounded-full hover:bg-slate-50 hover:bg-opacity-50 cursor-pointer" /> */}
      </a>

    </div>
   
    </div>
{/* Mobile */}
<div className="lg:hidden ml-auto flex flex-col justify-end">
<button onClick={toggleNavBar} className="ml-auto">

</button>
<div className={isOpen ? 'flex flex-col mr-auto h-[170px]  w-screen absolute top-20 left-0 px-6 pt-2' : 'hidden'}>
  {/* <Link href='/' className="text-yellow-300 font-medium text-xl" > Pradžia</Link>
            <Link href='/paslaugos' className="text-xl text-white" > Paslaugos</Link>
            <Link href='' className="text-xl text-white" > Forumas</Link>
            <Link href='' className="text-xl text-white" > Wiki</Link> */}

            <div className="flex gap-2 mt-5">
            <a href="/">
    {/* <Image src='/fb.png' alt="fb" width={40} height={40} className="bg-slate-50 bg-opacity-20 p-3 rounded-full hover:bg-slate-50 hover:bg-opacity-50 cursor-pointer" /> */}
      </a>
      <a href="/">

      {/* <Image src='/discord.svg' alt="dc" width={40} height={30} className="bg-slate-50 bg-opacity-20 p-3 rounded-full hover:bg-slate-50 hover:bg-opacity-50 cursor-pointer" /> */}
      </a>
      <a href="/">
      {/* <Image src='/tik.svg' alt="tik" width={40} height={30} className="bg-slate-50 bg-opacity-20 p-3 rounded-full hover:bg-slate-50 hover:bg-opacity-50 cursor-pointer" /> */}
      </a>
      <a href="/">
      {/* <Image src='/instagram.svg' alt="ig" width={40} height={30} className="bg-slate-50 bg-opacity-20 p-3 rounded-full hover:bg-slate-50 hover:bg-opacity-50 cursor-pointer" /> */}
      </a>
            </div>




</div>
</div>

    </nav>
    );
}

export default Nav;